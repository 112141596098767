'use client';

import type { Translation } from '@/globals/translate/translations';
import { classes } from '@/uiPrimitives/base/classes.helpers';
import { zIndex } from '@/uiPrimitives/layout/zIndex.css';
import {
	ComponentPropsWithoutRef,
	Dispatch,
	ReactNode,
	SetStateAction,
	createContext,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { ScrollInline, scrollLeft, scrollRight } from './ScrollInline';
import {
	measureAfter,
	measureBefore,
	scrollInlineSnap,
	scrollInlineSnapHideControls,
	scrollInlineSnapItem,
	scrollInlineWrapper,
	thumb,
	thumbItemsCount,
	thumbItemsPerPage,
	thumbOffset,
} from './ScrollInline.css';
import { block } from '@/uiPrimitives/utility/display.css';
import { ScrollButton } from './ScrollButton';
import { shelf } from '@/uiPrimitives/layout/shelf';
import { box } from '@/uiPrimitives/layout/box';
import { produce } from '@/globals/helpers/immer';
import { placement } from '@/uiPrimitives/layout/placement.css';
import { translateInline } from '@/globals/translate/translateInline';
import { interactive } from '@/uiPrimitives/interactive/interactive';
import { stack } from '@/uiPrimitives/layout/stack';
import { position } from '@/uiPrimitives/layout/position';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { addTestSelector } from '@dop/shared/helpers/testSelector';
import { useShowScrollButtons } from './useShowScrollButtons';

const scrollRatio = 1;

const SetItemsInViewportContext = createContext<
	Dispatch<SetStateAction<Array<boolean>>> | undefined
>(undefined);

const useSetItemsInViewportContext = () => {
	const context = useContext(SetItemsInViewportContext);
	if (context === undefined) {
		throw new Error(
			'useSetItemsInViewportContext must be used within a SetItemsInViewportContext',
		);
	}
	return context;
};

const scrollInlineIntoView = (scrollArea: HTMLElement, index: number) => {
	const item = scrollArea.querySelectorAll(`.${scrollInlineSnapItem}`)?.[index];

	if (item == null) return;

	const itemBox = item.getBoundingClientRect();
	const scrollAreaBox = scrollArea.getBoundingClientRect();

	const overflowLeft = Math.max(0, scrollAreaBox.left - itemBox.left);
	const overflowRight = Math.max(0, itemBox.right - scrollAreaBox.right);

	const scrollLeftTarget = scrollArea.scrollLeft - overflowLeft + overflowRight;

	scrollArea.scrollTo({
		left: scrollLeftTarget,
		behavior: 'smooth',
	});
};

export type ScrollInlineSnapProps = {
	className?: string;
	children?: ReactNode;
	itemsCount: number;
	/**
	 * The number of items visible in the scroll area viewport
	 * at different screen sizes.
	 *
	 * This is used on the server to calculate if the scrollbar should be visible.
	 * This way we can already show the scrollbar when the page is loaded.
	 * And align following items on the page in relation to the scrollbar
	 * or the content without a scrollbar.
	 */
	itemsPerPage: {
		[Key in keyof typeof scrollInlineSnapHideControls]: number;
	};
};

const ScrollThumb = ({
	itemsInViewport,
	itemsPerPage,
	itemsCount,
}: {
	itemsInViewport: Array<boolean>;
	itemsPerPage: ScrollInlineSnapProps['itemsPerPage'];
	itemsCount: number;
}) => {
	const offset = Math.max(itemsInViewport.indexOf(true), 0) / itemsCount;

	return (
		<i
			className={classes(thumb)}
			style={assignInlineVars({
				[thumbOffset]: String(offset),
				[thumbItemsCount]: String(itemsCount),
				[thumbItemsPerPage.max640px]: String(itemsPerPage.max640px),
				[thumbItemsPerPage.min640pxMax1120px]: String(
					itemsPerPage.min640pxMax1120px,
				),
				[thumbItemsPerPage.min1120px]: String(itemsPerPage.min1120px),
			})}
		/>
	);
};

export const ScrollInlineSnapContainer = ({
	children,
	className,
	beforeButtonLabel,
	afterButtonLabel,
	itemsCount,
	itemsPerPage,
}: ScrollInlineSnapProps & {
	beforeButtonLabel: Translation<'Scroll left in previous scroll area'>;
	afterButtonLabel: Translation<'Scroll right in previous scroll area'>;
}) => {
	const [itemsInViewport, setItemsInViewport] = useState<Array<boolean>>([]);
	const {
		measureBeforeRef,
		measureAfterRef,
		scrollAreaRef,
		showBeforeButton,
		showAfterButton,
	} = useShowScrollButtons();

	return (
		<SetItemsInViewportContext.Provider value={setItemsInViewport}>
			<span
				className={classes(
					className,
					scrollInlineWrapper,
					zIndex({ isolation: 'isolate' }),
				)}
			>
				<span className={classes(stack({ gap: '3 | h1' }))}>
					<ScrollInline
						scrollAreaClassName={scrollInlineSnap}
						ref={scrollAreaRef}
						notOverflowing={
							showBeforeButton === false && showAfterButton === false
						}
						measureBeforeSlot={
							<i className={classes(measureBefore)} ref={measureBeforeRef} />
						}
						measureAfterSlot={
							<i className={classes(measureAfter)} ref={measureAfterRef} />
						}
						scrollbarWidth="none"
					>
						<span className={classes(block)}>{children}</span>
					</ScrollInline>

					<span
						className={classes(
							shelf({
								gridTemplateColumns: 'minmax(0, 1fr) auto',
								gap: '1 | h3',
								alignItems: 'center',
							}),
							itemsCount <= itemsPerPage.max640px &&
								scrollInlineSnapHideControls.max640px,
							itemsCount <= itemsPerPage.min640pxMax1120px &&
								scrollInlineSnapHideControls.min640pxMax1120px,
							itemsCount <= itemsPerPage.min1120px &&
								scrollInlineSnapHideControls.min1120px,
						)}
					>
						<span
							className={classes(
								placement({
									gridRowStart: '1',
									gridColumnStart: '1',
									justifySelf: 'stretch',
								}),
								shelf({
									alignItems: 'center',
									justifyContent: 'stretch',
								}),
								box({
									blockSize: '0px',
									borderBlockStyle: 'solid',
									borderColor: 'negativeBlueEmphasis',
									borderWidth: '1px',
								}),
								position({ position: 'relative' }),
							)}
						>
							{itemsInViewport.map((inViewport, index) => {
								return (
									<button
										key={index}
										aria-disabled={inViewport}
										className={classes(
											box({
												blockSize: '2 | h2',
											}),
											inViewport && interactive({ cursor: 'default' }),
										)}
										aria-label={
											inViewport
												? translateInline({
														nl: `Item ${index + 1} van ${
															itemsInViewport.length
														} is in beeld in voorgaand scrollgebied`,
														en: `Item ${index + 1} of ${
															itemsInViewport.length
														} is in view in previous scroll area`,
													})
												: translateInline({
														nl: `Scroll naar item ${index + 1} van ${
															itemsInViewport.length
														} in voorgaand scrollgebied`,
														en: `Scroll to item ${index + 1} of ${
															itemsInViewport.length
														} in previous scroll area`,
													})
										}
										onClick={
											!inViewport
												? () => {
														if (scrollAreaRef.current == null) return;

														scrollInlineIntoView(scrollAreaRef.current, index);
													}
												: undefined
										}
									></button>
								);
							})}

							<ScrollThumb
								itemsInViewport={itemsInViewport}
								itemsPerPage={itemsPerPage}
								itemsCount={itemsCount}
							/>
						</span>

						<ScrollButton.Shelf
							className={classes(
								placement({
									gridRowStart: '1',
									gridColumnEnd: '-1',
								}),
								box({
									blockSize: '0px',
								}),
							)}
							{...addTestSelector('uiScrollInlineSnapButtons')}
						>
							<ScrollButton.Item
								side="before"
								isShowing={
									// To keep it in sync with item indicators
									itemsInViewport.at(0) === false
								}
								buttonLabel={beforeButtonLabel}
								onClick={() => {
									const scrollAreaElement = scrollAreaRef.current;
									if (scrollAreaElement == null) return;

									scrollLeft(scrollAreaElement, scrollRatio);
								}}
							/>
							<ScrollButton.Item
								side="after"
								isShowing={
									// To keep it in sync with item indicators
									itemsInViewport.at(-1) === false
								}
								buttonLabel={afterButtonLabel}
								onClick={() => {
									const scrollAreaElement = scrollAreaRef.current;
									if (scrollAreaElement == null) return;

									scrollRight(scrollAreaElement, scrollRatio);
								}}
							/>
						</ScrollButton.Shelf>
					</span>
				</span>
			</span>
		</SetItemsInViewportContext.Provider>
	);
};
export const ScrollInlineSnapItem = ({
	children,
	index,
	className,
	...props
}: {
	children: ReactNode;
	index: number;
} & ComponentPropsWithoutRef<'span'>) => {
	const setItemsInViewport = useSetItemsInViewportContext();
	const ref = useRef<HTMLLIElement>(null);

	useEffect(() => {
		if (ref.current == null) return;

		const root = ref.current.closest(`.${scrollInlineSnap}`);

		const observer = new IntersectionObserver(
			(entries) => {
				const entry = entries[0];
				setItemsInViewport(
					produce((draft) => {
						draft[index] = entry.isIntersecting;
					}),
				);
			},
			{
				threshold: 0.48,
				root,
			},
		);

		observer.observe(ref.current);

		return () => {
			observer.disconnect();
		};
	}, [index, setItemsInViewport]);

	return (
		<span
			{...props}
			className={classes(className, scrollInlineSnapItem)}
			ref={ref}
		>
			{children}
		</span>
	);
};
